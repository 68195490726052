<template>
  <div class="production_wrap">
    <div class = "box">
      <div class="about-title">科技创新</div>
      <div class = "block-line"></div>
      <div class="about-container">
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">大连市国土空间规划设计有限公司是规划体系改革后，东北地区首批高新技术企业，同时通过了雏鹰企业、科技型企业、创新型企业认定。空间规划公司拥有软件著作权34项和发明专利1项，在核心自主知识产权、科技成果转化能力、成长性指标和人才结构等方面具备较大竞争优势。</span> 
        </p>
        <div style="display: inline-block;text-align:center;width: 100%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/rz/“高新企业证书”大.jpeg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">高新企业证书</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 24%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/rz/“20231026-城市投资招商地图分析决策与应用系统”中.jpeg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">城市投资招商地图分析决策与应用系统</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 24%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/rz/“20231024-城市投资招商地图数据管理集成与挖掘系统”中.jpeg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">城市投资招商地图数据管理集成与挖掘</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 24%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/rz/“20231024-实景三维展示与应用系统”中.jpeg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">实景三维展示与应用系统</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 24%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/rz/“20231108-海洋综合管理系统”中.jpeg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">海洋综合管理系统</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 24%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/rz/“20231102-规划指标审核与综合平衡管理系统”中.jpeg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">规划指标审核与综合平衡管理系统</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 24%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/rz/“20231026-城市体检评估社会公众意见云服务系统”中.jpeg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">城市体检评估社会公众意见云服务系统</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 24%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/rz/“20230120-国土空间规划全流程在线综合服务平台”中.jpeg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">国土空间规划全流程在线综合服务平台</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 24%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/rz/“20230120-规划编制项目一体化管理系统”中.jpeg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">规划编制项目一体化管理系统</div> 
        </div>
        <!-- <table cellspacing="0" style="width:100%;border:none;" class="ke-zeroborder">
          <tbody>
            <tr>
              <td width="85" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <b><span style="font-family:华文仿宋;font-size:18px;">序号</span></b><b><span style="font-family:华文仿宋;font-size:14pt;"></span></b> 
                </p>
              </td>
              <td width="372" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <b><span style="font-family:华文仿宋;font-size:18px;">论文名称</span></b><b><span style="font-family:华文仿宋;font-size:14pt;"></span></b> 
                </p>
              </td>
              <td width="148" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <b><span style="font-family:华文仿宋;font-size:18px;">获奖情况</span></b><b><span style="font-family:华文仿宋;font-size:14pt;"></span></b> 
                </p>
              </td>
              <td width="235" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <b><span style="font-family:华文仿宋;font-size:18px;">类别</span></b><b><span style="font-family:华文仿宋;font-size:14pt;"></span></b> 
                </p>
              </td>
              <td width="142" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <b><span style="font-family:华文仿宋;font-size:18px;">年份</span></b><b><span style="font-family:华文仿宋;font-size:14pt;"></span></b> 
                </p>
              </td>
            </tr>
            <tr>
              <td width="85" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">1</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="372" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:justify;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">《基于“数据有机体”的国土空间规划智慧化支撑体系构建探索与实践——以大连市国土空间规划“一张图”数据治理为例》</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="148" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">三等奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="235" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">全国国土空间规划年会</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="147" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">2023</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
            </tr>
            <tr>
              <td width="85" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">2</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="372" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:justify;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">《国土空间规划体系下详细规划编制和实施管理制度改革的地方实践样本——大连市详细规划编制管理实斾机制研究的探索与实践》</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="148" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">优秀奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="235" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">全国国土空间规划年会</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="142" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">2023</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
            </tr>
          </tbody>
        </table> -->
      </div>
    </div>
    <CallMe />
    <MobileCallme />
  </div>
</template>

<script>
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
export default {
  data() {
    return {
    }
  },
  components: {
    CallMe,
    MobileCallme,
  },
}
</script>

<style lang="scss" scoped>
.production_wrap {
  padding-top: 200px;
  width: 100%;
}
</style>